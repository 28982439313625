import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { MailchimpLinks } from "../components/mailchimp"
import Calendar from '../components/Events/EmbeddedCalendar';

const FindUsPage = () => (
  <Layout>
    <SEO title="Find EZAA Online" />
    <article>
      <h1>Find EZAA Online</h1>
      <section>
        <h3>Join us on Facebook</h3>
        <p>Join the discussion and receive updates on our <a href="https://www.facebook.com/apoezaa/" target="_blank" rel="noopener noreferrer">Facebook group</a>.</p>
        <MailchimpLinks />
        <Calendar />
      </section>
    </article>
  </Layout>
)

export default FindUsPage;
