import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const MailchimpLinks = () => (
  <>
    <h3>Sign up for email updates</h3>
    <p>Please <a href="https://mailchi.mp/8337b0c98e87/ezaa-subscribe" target="_blank" rel="noopener noreferrer">sign up</a> for email updates from EZAA!
    You can also view an <a href="https://us19.campaign-archive.com/home/?u=606e3e9daf28f1aeb6403ed47&id=2714ecf937" target="_blank" rel="noopener noreferrer">archive</a> of previously-sent messages.</p>
  </>
);

class MailchimpForm extends React.Component {
  render() {
    return (
      <>
        Mailchimp!
      </>
    )
  }
}

export {
  MailchimpLinks,
  MailchimpForm,
};
