import React from 'react';

const EmbeddedCalendar = () => (
  <iframe
    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&src=YXBvLmV6YWFAZ21haWwuY29t&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=cW02anRpYmVtNm05ZXM2MTRmZGNtNDN2ZDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%2333B679&color=%23616161&color=%230B8043"
    style={{border: 'solid 1px #777'}}
    width="800"
    height="600"
    frameborder="0"
    scrolling="no">
  </iframe>
);

EmbeddedCalendar.displayName = 'components/Events/EmbeddedCalendar';

export default EmbeddedCalendar;
